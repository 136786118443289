export default {
  'almadaq': 'Al-Madaq',
  'about': 'About Al-Madaq',
  'maps': 'Maps',
  'articles': 'Articles',
  'multimedia': 'Multimedia',
  'contact': 'Contact Us',
  'message has been sent': 'Message has been sent.',
  'error': 'Error.',
  'send': 'Send',
  'name': 'Name',
  'subject': 'Message Subject',
  'subscribed successfuly': 'You\'ve been subscribed successfully',
  'email': 'Email Address',
  'subscribe': 'Subscribe',
  'loading': 'Loading',
  'load more': 'Load more',
  'author': 'By',
  'shehab': 'Shehab Fakhry Ismail',
  'privacy policy': 'Privacy Policy',
  'maps list': 'Maps List',
  'tools': 'Control Tools',
  'satallite images': 'Satellite Images',
  'read more': 'Read more',
  'how to use the archive': 'How to Use the Archive',
  'how to read the articles': 'How to Browse the Articles',
  'share': 'Share',
  'get link': 'Get a shareable link',
  'link': 'The link',
  'copy': 'Copy',
  'copied': 'Copied',
  'embed code': 'Embed Code',
}

