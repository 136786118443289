import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ar from './ar'
import en from './en'
export { default as withTranslation } from './with-translation'

let isEnglish = false

if (typeof window !== 'undefined') {
  const { pathname } = window.location
  isEnglish = pathname.match(/^\/en\/|^\/en$/)
}

const lng = isEnglish ? 'en' : 'ar'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      lng,
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
    },
    fallbackLng: 'ar',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
