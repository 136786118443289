export default {
  'almadaq': 'المدق',
  'about': 'عن المدق',
  'maps': 'خرائط',
  'articles': 'مقالات',
  'multimedia': 'صوت وصورة',
  'contact': 'اتصل بنا',
  'message has been sent': 'تم الارسال بنجاح',
  'error': 'خطأ',
  'send': 'إرسال',
  'name': 'الإسم',
  'subject': 'عنوان الرسالة',
  'subscribed successfuly': 'تم الاشتراك  بنجاح',
  'email': 'البريد الإلكتروني',
  'subscribe': 'إشترك',
  'loading': 'تحميل',
  'load more': 'تحميل المزيد',
  'author': 'كتابة',
  'shehab': 'شهاب فخري إسماعيل',
  'privacy policy': 'سياسة الخصوصية',
  'maps list': 'قائمة الخرائط',
  'tools': 'أدوات التحكم',
  'satallite images': 'صور القمر الصناعي',
  'read more': 'اقرأ المزيد',
  'how to use the archive': 'كيف تستخدمـ(ين) الأرشيف؟',
  'how to read the articles': 'كيف تتصفحـ(ين) المقالات؟',
  'share': 'مشاركة',
  'get link': 'الحصول علي الرابط',
  'link': 'الرابط',
  'copy': 'نسخ',
  'copied': 'تم النسخ',
  'embed code': 'كود التضمين',
}
