import React, { Component } from 'react'
import i18next from './index'
import { I18nextProvider, withTranslation as I18nWithTranslation } from 'react-i18next'

const withTranslation = (WrappedComponent) => {
  WrappedComponent = I18nWithTranslation()(WrappedComponent)

  return class extends Component {
    handleLanguage () {
      if (typeof window !== 'undefined') {
        const { pathname } = window.location
        const isEnglish = pathname.match(/^\/en\/|^\/en$/)
        const lang = i18next.language

        if (isEnglish) {
          if (lang !== 'en') {
            i18next.changeLanguage('en')
          }
        } else {
          if (lang !== 'ar') {
            i18next.changeLanguage('ar')
          }
        }
      }
    }

    componentDidMount () {
      this.handleLanguage()
    }

    componentDidUpdate () {
      this.handleLanguage()
    }

    render() {
      return (
        <I18nextProvider i18n={i18next}>
          <WrappedComponent {...this.props} language={i18next.language} />
        </I18nextProvider>
      )
    }
  }
}

export default withTranslation
